<template>
  <div>
    <div class="row justify-content-top">
      <div class="col-auto">
        <div class="avatar avatar-giant">
          <img
            :src="info.img"
            :alt="info.name"
            class="shadow-xl w-100 border-radius-lg"
          />
        </div>

        <div class="mt-2">
          <h5 class="font-weight-light">{{ info.name }}</h5>
          <p class="text-sm">{{ info.job }}</p>
        </div>
      </div>

      <div class="col-12 col-sm">
        <div class="row">
          <p v-html="info.description" />
        </div>
        <div class="row">
          <p class="text-end" v-html="info.signature" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "co-owner-section",

  props: {
    info: {
      type: Object,
      require: true,
    },
  },
};
</script>
