<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <Navbar />

    <router-view />

    <FooterApp />
  </main>
</template>

<script>
import Navbar from "@/layout/Navbar.vue";
import FooterApp from "@/layout/FooterApp.vue";

export default {
  name: "App",

  components: {
    Navbar,
    FooterApp,
  },
};
</script>
