import { createRouter, createWebHistory } from "vue-router";

import Landing from "@/views/Landing.vue";
import OurStory from "@/views/OurStory.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

import WelcomePage from "@/views/WelcomePage.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: Landing,
  },
  {
    path: "/our-story",
    name: "our-story",
    component: OurStory,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },

  {
    path: "/welcome/:id",
    name: "welcome",
    component: WelcomePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
