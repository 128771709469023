<template>
  <div class="bg-white">
    <div class="container pt-4">
      <nav class="navbar navbar-expand-lg shadow-xl border-radius-lg">
        <div class="container px-0">
          <router-link
            class="text-dark font-weight-bolder"
            to="/"
            v-bind="$attrs"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <img
                  :src="logo"
                  alt="Your Family Album"
                  class="avatar avatar-md shadow-xl border-radius-md"
                />
              </div>
              <div class="col ps-0">
                <h3 class="font-weight-light my-0 py-2">Your Family Album</h3>
              </div>
            </div>
          </router-link>

          <button
            class="navbar-toggler shadow-none ms-2 ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon mt-2">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </span>
          </button>

          <div class="collapse navbar-collapse" id="navigation">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link
                  class="nav-link d-flex align-items-center me-2 active"
                  aria-current="page"
                  to="/our-story"
                >
                  <i
                    class="text-dark fa fa-book opacity-6 me-2"
                    aria-hidden="true"
                  />
                  <h6 class="font-weight-light my-0">Our Story</h6>
                </router-link>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link d-flex align-items-center me-2 active"
                  :href="blog_url"
                  target="_blank"
                >
                  <i
                    class="text-dark fa fa-medium opacity-6 me-2"
                    aria-hidden="true"
                  />
                  <h6 class="font-weight-light my-0">Blog</h6>
                </a>
              </li>

              <li class="nav-item">
                <a
                  :href="contact_url"
                  class="nav-link d-flex align-items-center me-2 active"
                >
                  <i
                    class="text-dark fa fa-envelope opacity-6 me-2"
                    aria-hidden="true"
                  />
                  <h6 class="font-weight-light my-0">Contact Us</h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { contact_url, blog_url } from "../config/app";
import logo from "@/assets/img/logo.webp";

export default {
  name: "navigation-bar",

  data() {
    return {
      contact_url: contact_url,
      blog_url: blog_url,
      logo: logo,
    };
  },
};
</script>
