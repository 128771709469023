<template>
  <div class="bg-white">
    <div class="container">
      <main class="main-content pb-7">
        <div class="page-header">
          <div class="container mt-6">
            <h1 class="font-weight-light text-center">
              All-in-one resource to create family history through photos and videos
            </h1>
          </div>
        </div>

        <FamilyWithKidsSection class="mt-4">
          We rethink what a photo service for families should be. Google Photos and similar are very nice 
          one-photo-tool-for-everything-and-everyone. But it’s not the best at any specific thing.
          We make Your Family Album for families only, to fully meet their needs.
          <br/>To bring more joy to the family, grandparents, and other loved ones. To keep family history alive.
        </FamilyWithKidsSection>

        <YourPhotoStorySection class="mt-7" />
        <YourFamilySection class="mt-6" />

        <WeMakeItEasySection class="mt-6" />

        <CallToSubscribeSection class="mt-6" />
      </main>
    </div>
  </div>
</template>

<script>
import FamilyWithKidsSection from "./components/sections/FamilyWithKidsSection.vue";
import YourPhotoStorySection from "./components/sections/YourPhotoStorySection.vue";
import YourFamilySection from "./components/sections/YourFamilySection.vue";
// import WeMakeItEasySection from "./components/sections/WeMakeItEasySection.vue";
// import CallToSubscribeSection from "./components/sections/CallToSubscribeSection.vue";

export default {
  name: "landing-home",

  components: {
    FamilyWithKidsSection,
    YourPhotoStorySection,
    YourFamilySection,
    // WeMakeItEasySection,
    // CallToSubscribeSection,
  },
};
</script>
