<template>
  <div class="row">
    <div class="col-xs-6 col-md-6 col-lg">
      <div class="ps-3">
        <h2 class="mb-2 font-weight-light">What makes a family history</h2>
        <p>It is not only a bunch of photos and videos stored on a photo service. </p>
      </div>
    </div>

    <div class="col-xs-6 col-md-6 col-lg">
      <PhotoStoryCard
        :image="img2"
        title="Collaborative contribution"
        description="Parents are full-fledged contributors without any limitations. 
        No partner sharing or shared library anymore."
      />
    </div>

    <div class="col">
      <PhotoStoryCard
        :image="img1"
        title="Single continued story"
        description="Same context for everyone who is allowed to view the family album. Stop sharing via messengers."
      />
    </div>

    <div class="col">
      <PhotoStoryCard
        :image="img3"
        title="Keep it alive"
        description="Really family owned account. It is not tied to either parent. But time runs fast, when 
        kids grow up they will become owners of the family album to continue the family story."
      />
    </div>
  </div>
</template>

<script>
import PhotoStoryCard from "@/layout/cards/PhotoStoryCard.vue";

import img1 from "@/assets/img/family_history_1.webp";
import img2 from "@/assets/img/family_history_2.webp";
import img3 from "@/assets/img/family_history_3.webp";

export default {
  name: "photo-story-section",

  components: {
    PhotoStoryCard,
  },

  data() {
    return {
      img1,
      img2,
      img3,
    };
  },
};
</script>
