<template>
  <footer class="footer position-relative py-2 w-100">
    <div class="container">
      <div class="mx-auto my-3 text-center col-lg-8">
        <router-link
          class="text-secondary font-weight-light"
          to="/our-story"
        >
          Our Story
        </router-link>

        <a
          class="ms-4 text-secondary font-weight-light"
          :href="blog_url"
          target="_blank"
        >
          Blog
        </a>

        <router-link
          class="mx-4 text-secondary font-weight-light"
          to="/terms-of-use"
        >
          Terms of Use
        </router-link>

        <router-link
          class="me-4 text-secondary font-weight-light"
          to="/privacy-policy"
        >
          Privacy Policy
        </router-link>

        <a class="text-secondary font-weight-light" :href="contact_url">
          Contact Us
        </a>
      </div>

      <div class="mx-auto mt-4 mb-3 text-center col-lg-8">
        <div class="copyright text-secondary text-xs">
          © {{ new Date().getFullYear() }} made with
          <i class="fa fa-heart" aria-hidden="true"></i> by
          <a href="https://krezzoid.com" class="text-dark" target="_blank">
            KreZZoiD</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { contact_url, blog_url } from "../config/app";

export default {
  name: "footer-app",

  data() {
    return {
      contact_url: contact_url,
      blog_url: blog_url,
    };
  },
};
</script>
