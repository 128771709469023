<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-md-6 col-lg">
        <PhotoStoryCard
          :image="img1"
          title="Personalisation"
          description="Actually, personalisation is the thing that turns just an archive of photos into a real family story.
          We put a lot of efforts to personalise it for everyone."
        />
      </div>

      <div class="col">
        <PhotoStoryCard
          :image="img2"
          title="Context"
          description="We introduce hashtags to tag whatever family consider important on the photos and wants to remember.
          Not the things that can be recognisable by modern smart technologies."
        />
      </div>

      <div class="col">
        <PhotoStoryCard
          :image="img3"
          title="Sharing"
          description="One-click sharing. Family-to-family sharing. Smart sharing. Easy!
          To bring even more joy to loved ones by sharing more."
        />
      </div>

      <div class="col-xs-6 col-md-6 col-lg order-first order-lg-last">
        <div class="ps-3">
          <h2 class="mb-2 font-weight-light">What distinguishes the service</h2>
          <p>We create a service for one specific audience only - families. This means that all the features 
            we are implementing are aimed for family use cases. To trigger emotions like joy, happiness, 
            laugh and smile, nostalgy and others.
            We don't compete with Google, Apple or Amazon.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoStoryCard from "@/layout/cards/PhotoStoryCard.vue";

import img1 from "@/assets/img/distinguish_1.webp";
import img2 from "@/assets/img/distinguish_2.webp";
import img3 from "@/assets/img/distinguish_3.webp";

export default {
  name: "photo-story-section",

  components: {
    PhotoStoryCard,
  },

  data() {
    return {
      img1,
      img2,
      img3,
    };
  },
};
</script>