<template>
  <div class="card shadow-xl">
    <div class="card-header pb-0">
      <img
        :src="image"
        alt="img-blur-shadow"
        class="shadow img-fluid border-radius-xl"
        style="width: 100%; object-fit: cover; max-height: 500px"
      />
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col">
          <h5 class="font-weight-light">
            <slot />
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from "@/assets/img/family_back.webp";

export default {
  name: "family-with-kids-section",

  components: {},

  data() {
    return {
      image: image,
    };
  },
};
</script>
