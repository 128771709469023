<template>
  <div class="bg-white">
    <div class="container">
      <main class="main-content pb-6">
        <div class="page-header">
          <div class="container mt-6">
            <h3 class="font-weight-light text-center">
              The most meaningful part of every family's history is stored in
              photos. Our goal is to make your digital experience of sharing and
              managing your photo memories unique and enjoyable!
            </h3>

            <div class="mt-6">
              <h4 class="font-weight-light text-center">
                Meet our founders and their histories of how and why they have
                decided to start this venture for you.
              </h4>
            </div>
          </div>
        </div>

        <template v-for="row in items" :key="row.id">
          <div class="page-item mt-6">
            <CoOwnerSection :info="row" />
          </div>
        </template>
      </main>
    </div>
  </div>
</template>

<script>
import CoOwnerSection from "./components/sections/CoOwnerSection.vue";
import photoAlex from "@/assets/img/alexey.webp";
import photoIlya from "@/assets/img/ilya.webp";

export default {
  name: "our-story",

  components: {
    CoOwnerSection,
  },

  data() {
    return {
      items: [
        {
          id: 0,
          img: photoAlex,
          name: "Aleksei Kuramshin",
          job: "Co-Founder",
          description: `
                <strong>M</strong>y first daughter was born in 2003. Since then I have been constantly taking photos of my family. Most of them aren’t professional.
                Some of them are in quite poor quality because back then I used a pretty simple digital camera.
                But nevertheless all of them are really dear to my wife and me. Once I realized two things. The first one is the amount of photos.
                There are dozens of thousands of photos in our family photo album. And the second one is my wife is also taking photos. Funny thing for sure.
                Yes, I had seen almost all of them, but not all. Because usually we share the photos we think are high quality, beautiful, funny or otherwise distinctive.
                But family, you know, is about not only distinguished things.
                <br><br><strong>A</strong>ll the time I have been thinking how to organize the photo album. That time it was hardly possible because there were no face
                recognition tools and cloud storage was not widely adopted. So the only thing I could do is to sort them out by folders on my local PC.
                <br><br><strong>L</strong>ong story short, recently I decided to move all the photos to a cloud. While copying, it turned out that our daughter,
                the family photo album began from, is also taking photos! She is shooting her little sister. Now we are a family of four.
                She takes photos from travelling. Today she is 19 so can travel alone. She is simply adding the photos she has taken to our
                common album. That moment I had a feeling that as a father I do want to see whatever she is shooting. I'm genuinely interested in it.
                And I do understand how important it is to share photos to the parents. So the second thing that came to my mind is that a family
                photo album is not just photos you or your partner takes. It is also photos which your kid or kids are already doing or will do in the near future.
                The thing is the family photo album is the history that never ends. And my wife and me need to pass the family photos to our daughters.
                And they will do the same thing at the proper time. It is really important to keep the history of the family.
                <br><br><strong>T</strong>hat’s the reason why I decided to create this Family Photo Album. To get photos from our daughter. To share photos to our parents.
                And to pass the photo album down to the next generation.
                `,
          signature:
            "<strong>Aleksei</strong>, father of two girls and a Co-Founder of Family Photo Album",
        },

        {
          id: 1,
          img: photoIlya,
          name: "Ilya Seliverstov",
          job: "Co-Founder",
          description: `
          <strong>I</strong>, a father of two boys, am a big photography enthusiast. I love capturing important moments in the lives of my family and
          friends, so that I can later enjoy the memories by looking back at the photos. However, I have always had one big problem - I couldn't easily
          share photos with my close friends and relatives who live very far away.
          <br><br>
          <strong>O</strong>f course, I could send them photos using any of the modern messengers or email, but let's face it - it's not very
          convenient. Especially when it comes to albums with a large number of photos. Besides, you often lose control over who is viewing these
          photos, commenting on them, and forwarding them to someone else. I think it's not very pleasant when photos of you in not the best light
          are available for viewing by strangers.
          <br><br>
          <strong>O</strong>nce I had the opportunity to meet Aleksei, who shared my problems. So together, we decided to create a new service that
          will help people not only conveniently share photos with their loved ones, but also share the story that is always associated with the photo.
          And, we hope that our service will allow you to preserve and pass on these memories to your children and grandchildren, no matter how far
          they are. So that they can enjoy your memories, feelings and of course - photos at any time.
          `,
          signature:
            "<strong>Ilya</strong>, father of two boys and a Co-Founder of Family Photo Album",
        },
      ],
    };
  },
};
</script>
