<template>
  <div>
    <div class="card card-blog card-plain">
      <div class="card-header p-0 mt-2">
        <a class="shadow-xl d-block border-radius-xl">
          <img
            :src="image"
            alt="img-blur-shadow"
            class="shadow img-fluid border-radius-xl"
          />
        </a>
      </div>
      <div class="p-3 card-body">
        <h5 class="font-weight-light">{{ title }}</h5>
        <p class="text-sm">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "photo-story-card",

  props: {
    image: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },
  },
};
</script>
