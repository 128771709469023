<template>
  <div class="bg-white">
    <div class="container">
      <main class="main-content pb-7">
        <div class="page-header">
          <div class="container mt-6">
            <h1 class="font-weight-light text-center">
              Сервис для создания истории семьи в фото и видео
            </h1>
          </div>
        </div>

        <FamilyWithKidsSection class="mt-4" >
          <h2 class="font-weight-light text-center">Привет, {{ $route.params.id }} и семья!</h2>
          Мы рады представить вам семейный фотосервис. Мы точно знаем, что поддержание порядка в семейной коллекции — это настоящая головная боль.
        </FamilyWithKidsSection>

        <ImageWithText
          :image="imgNoTime"
          class="mt-4">
          <div>
            <ul class="no-dots ms-2">
              <li>
                <i class="fas fa-thumbs-down rose-icon me-2" />У меня на это <span class="underscored-rose">нет времени</span>.
              </li>
              <li>
                <i class="fas fa-thumbs-down rose-icon me-2 mt-3" />Фото появляются быстрее, чем наводится порядок.
              </li>
              <li>
                <i class="fas fa-thumbs-down rose-icon me-2 mt-3" />Добавлять к фотографиям описания? Столько <span class="underscored-rose">времени точно нет</span>. 
              </li>
              <li>
                <i class="fas fa-thumbs-down rose-icon me-2 mt-3" />А еще чем больше фото и видео, тем сложнее их пересматривать.
                Многие фото <span class="underscored-rose">видите только раз</span> — сразу после съемки.
              </li>
            </ul>
          </div>
        </ImageWithText>

        <div class="container mt-6">
          <h2 class="font-weight-light text-center">
            Мы создаем сервис, который <span class="underscored-green">организует</span> вашу семейную коллекцию, занимая <span class="underscored-green">минимум</span> вашего времени!
          </h2>
        </div>

        <ImageWithText
          :image="imgOrder"
          class="mt-4">
          Организует фото и наведет в них порядок.
        </ImageWithText>

        <ImageWithText
          :image="imgHolidays"
          class="mt-4">
          Пишет заголовки и подписи к фото на основе профиля вашей семьи и контекста снимка.
        </ImageWithText>

        <ImageWithText
          :image="imgGrandParents"
          class="mt-4">
          Создает персонализированные подборки фото и видео. Чтобы и вы, и бабушки с дедушками пересматривали коллекцию снова и снова.
          Платите за радость, а не за место для хранения.
        </ImageWithText>

        <div class="container mt-6">
          <h2 class="font-weight-light text-center">
            Как это работает
          </h2>
        </div>

        <ImageWithText
          :image="imgToCloud"
          class="mt-4">
          Вы загружаете несколько сотен или даже тысяч фото.
        </ImageWithText>

        <ImageWithText
          :image="imgToOrder"
          class="mt-4">
          Сервис будет наводить порядок и предлагать варианты организации.
        </ImageWithText>

        <ImageWithText
          :image="imgThumbsUp"
          class="mt-4">
          Вам надо будет только нажимать<i class="fas fa-thumbs-up green-icon me-2 ms-2" /><span class="font-weight-normal underscored-green">"Оставить"</span> или <i class="fas fa-thumbs-down rose-icon me-2 ms-2" /><span class="font-weight-normal underscored-rose">"Переделать"</span>.
          <hr class="mt-4 mb-4" style="border-color: grey;">
          В результате сервис организует уже загруженные фото и будет поддерживать порядок в новых.
        </ImageWithText>

        <div class="container mt-6">
          <h2 class="font-weight-light text-center">
            С уважением, создатели Your Family Album, Алексей и Илья.
          </h2>

          <div class="row justify-content-center">
            <div class="col-auto">
              <a href="https://de.linkedin.com/in/akuramshin" target="_blank">
                <MaterialAvatar :img="imgAlexey" :style="{ border: 'solid #BDD061' }"
                  size="xxl" alt="Alexey" border-radius="lg" shadow="xl" circular
                />
              </a>
            </div>
            <div class="col-auto ">
              <a href="https://krezzoid.com" target="_blank">
                <MaterialAvatar :img="imgilya" :style="{ border: 'solid #BDD061' }"
                  size="xxl" alt="Ilya" border-radius="lg" shadow="xl" circular
                />
              </a>
            </div>
          </div>

        </div>

      </main>
    </div>
  </div>
</template>

<script>
import FamilyWithKidsSection from "./components/sections/FamilyWithKidsSection.vue";
import ImageWithText from "./components/sections/ImageWithText.vue";
import MaterialAvatar from "../components/MaterialAvatar.vue";

import imgNoTime from "@/assets/img/no_time.webp";
import imgOrder from "@/assets/img/order.webp";
import imgHolidays from "@/assets/img/holidays.webp";
import imgGrandParents from "@/assets/img/grandparents.webp";
import imgToCloud from "@/assets/img/to_cloud.webp";
import imgToOrder from "@/assets/img/to_order.webp";
import imgThumbsUp from "@/assets/img/thumbs_up.webp";

import imgAlexey from "@/assets/img/alexey.webp";
import imgilya from "@/assets/img/ilya.webp";

export default {
  name: "welcome-page",

  components: {
    FamilyWithKidsSection,
    ImageWithText,
    MaterialAvatar,
  },

  data() {
    return {
      imgNoTime,
      imgOrder,
      imgHolidays,
      imgGrandParents,
      imgToCloud,
      imgToOrder,
      imgThumbsUp,
      imgAlexey,
      imgilya,
    };
  },

};
</script>

<style>
.no-dots {
  list-style-type: none;
  padding-left: 0;
}

.rose-icon {
  color: #F87979;
}

.green-icon {
  color: #BDD061;
}

.underscored-green {
  text-decoration: underline;
  text-decoration-color: #BDD061;
}

.underscored-rose {
  text-decoration: underline;
  text-decoration-color: #F87979;
}
</style>