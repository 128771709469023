<template>
  <div class="bg-white">
    <div class="container">
      <main class="main-content pb-6">
        <div class="page-header">
          <div class="container mt-6">
            <h3 class="font-weight-light text-center">
              Terms of Use for the Your Family Album
            </h3>

            <p v-html="termsInfo" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms-of-story",

  data() {
    return {
      termsInfo: `
                <strong>Introduction</strong>
                <br>“Your Family Album” is a service provided by Tovsla and Vivsla (hereinafter referred to as the “Company”) which allows its users to create and share with their family and relatives an electronic photo album containing a collection of photos and videos (hereinafter referred to as the “Service”).
                <br><br>The Service itself consists of two parts: a mobile application (hereinafter referred to as the “Application”) and the server (hereinafter referred to as the “Server”). The Application provides a user interface to all the features of the Service. Content of the Users of the service are processed and stored on the Server.
                <br><br>Please read and agree to the Terms of Use for Your Family Album (hereinafter referred to as the “TOU”) prior to using the Service. Users of the Service (hereinafter referred to as the “Users”) shall agree to all the contents of the TOU.
                <br><br>The Company may modify the TOU by publishing the details and time of such modification in an appropriate manner, such as posting them on the Service or sending them by e-mail to individual Users. Unless otherwise provided for by the Company, any modified version of the TOU shall come into force when the User uses the Service for the first time after the modified TOU are posted on the Service.
                
                <br><br><strong>License</strong>
                <ul>
                  <li/>The Company grants you a non-exclusive, non-transferable, revocable license to use our application for the sole purpose of uploading photos to our server. However, the TOU shall not grant a license to use any version upgrades, etc. of the App to be developed in the future.
                  <li/>The Company may, without any notice to the Users, modify the functions or specifications of the App.
                  <li/>The Application may connect to and communicate with the network at regular intervals. In such a case, a communication fee will be separately charged
                  <li/>Any program data, related document files, etc. required for the display and the operation of the Application may be automatically modified or updated without notice
                </ul>
                
                <br><strong>Ownership</strong>
                <br>The Company owns all intellectual property rights in our application, including any trademarks, copyrights, and patents. You agree not to modify, distribute, or create derivative works based on the Application without our prior written consent.

                <br><br><strong>User Content</strong>
                <br>By uploading photos to the Service, you grant the Company a non-exclusive, worldwide license to use your content for the purpose of providing our services. You represent and warrant that you have all necessary rights and permissions to upload your content and that it does not infringe on any third-party rights.

                <br><br><strong>User Conduct</strong>
                <br>You agree not to use the Service for any unlawful or fraudulent purpose, or to upload any content that is obscene, defamatory, or infringes on any third-party rights. You also agree not to use any automated means to upload content to our server.

                <br><br><strong>Disclaimer of Warranties</strong>
                <br>The Service is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement.

                <br><br><strong>Limitation of Liability</strong>
                <br>The Company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Service, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.

                <br><br><strong>Indemnification</strong>
                <br>You agree to indemnify, defend, and hold the Company harmless from any claims, damages, expenses, or losses arising out of your use of the Service or any violation of these terms of use.
                
                <br><br><strong>Governing Law</strong>
                <br>These terms of use shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is located, without giving effect to any principles of conflicts of law.

                <br><br><strong>Modification</strong>
                <br>The Company may, at its discretion and at any time, restrict, add and suspend the use of all or part of the functions of the Service, or modify, discontinue and terminate the provision thereof. The Company shall not be liable for any damage suffered by the Users arising from modification, restriction of use, suspension, or discontinuance and termination of the provision of the Service and the App, except in the case caused by the Company’s intent or gross negligence.
                <br><br>By using the Service, you acknowledge that you have read and understood these terms of use and agree to be bound by them. If you do not agree to these terms, please do not use the Service.
                `,
    };
  },
};
</script>
