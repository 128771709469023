<template>
  <div class="bg-white">
    <div class="container">
      <main class="main-content pb-6">
        <div class="page-header">
          <div class="container mt-6">
            <h3 class="font-weight-light text-center">Privacy Policy</h3>

            <p v-html="termsInfo" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy-policy",

  data() {
    return {
      termsInfo: `
      Thank you for using the service Your Family Album. Security of the data is the high priority for our team. This privacy policy explains how we collect, use, and share information about you when you use the service.

      <br><br><strong>Before we start</strong>
      <br>Your Family Album is a service that helps to create, organize and share family photos and videos.
      <ul>
        <li/>All the digital content you upload and then keep in the service is completely private. This means that <strong>only you and the people you grant explicit permission</strong> are allowed to see the photos and videos
        <li/>There are no third-party ads in the service, and we don't share your data with advertisers
        <li/>Our servers are protected by a strong firewall, secure passwords, two-factor authentication, SSH key authentication, etc.
      </ul>
                
      <br><strong>Information you provide and we collect</strong>
      <br>In order to register and use the service you need to provide your email. For your family members you should provide their names.
      <br>While not required, you may choose to provide additional information to customize your experience. This could be dates of birth, personal names etc.
      <br>We also collect information about the photos you upload to our service, including metadata such as date and time, location, and device information.
      
      <br><br><strong>How we use your information</strong>
      <br>We use the information we collect to provide, maintain, and improve our service. This includes analyzing how our service is used, diagnosing service or technical problems, and providing customer support. We may also use the information we collect to personalize your experience and to communicate with you about our service.

      <br><br><strong>How we share your information</strong>
      <br>We do not share your personal information with third parties except as necessary to provide our service. For example, we may share your information with our cloud storage provider to store your photos. We may also share your information if required by law or to protect our rights or the rights of others.

      <br><br><strong>Your choices</strong>
      <br>You can choose not to provide us with certain information, but this may limit your ability to use our service. You can also opt out of receiving promotional emails from us by following the instructions in those emails. Please note that even if you opt out of receiving promotional emails, we may still send you non-promotional emails related to your account or our service.

      <br><br><strong>Security</strong>
      <br>We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, no security measures are perfect, and we cannot guarantee the security of your information.

      <br><br><strong>Changes to this policy</strong>
      <br>We may update this privacy policy from time to time. If we make significant changes, we will notify you by email or by posting a notice on our website prior to the changes becoming effective.

      <br><br><strong>Contact us</strong>
      <br>If you have any questions about this privacy policy, please contact us at contact@familyinpics.com
      `,
    };
  },
};
</script>
