import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

import MaterialDashboard from "./material-dashboard";

const appInstance = createApp(App);
appInstance
  .use(store)
  .use(router)
  .use(MaterialDashboard)

  .mount("#app");
